@font-face {
  font-family: 'GT America Rg';
  src: url('./fonts/GTAmerica-Rg.woff2') format('woff2'),
  url('./fonts/GTAmerica-Rg.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT America Rg';
  src: url('./fonts/GTAmerica-Bd.woff2') format('woff2'),
  url('./fonts/GTAmerica-Bd.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
