:root {
  --main-publicis-primary-black: rgba(33, 37, 41, 1);
  --main-publicis-primary-red: rgba(214, 33, 48, 1);
  --main-publicis-primary-white: rgba(255, 255, 255, 1);
  --main-publicis-secondary-mid-gray: rgba(148, 148, 148, 1);
  --main-publicis-secondary-light-gray: rgba(238, 238, 238, 1);
  --main-breakpoint-sm: 450px;
  --main-breakpoint-md: 768px;
  --main-publicis-border-radius: 4px;
}
